import React, { useState } from "react";
import styles from "./EditFaqCategory.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import PageHeaderTwo from "../../../../../components/PageHeader/PageHeaderTwo";
import {  userRequest } from "../../../../../requestMethod";

function EditFaqCategory() {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location?.state?.item;
  console.log("item" , item)

  const initialFormData = {
    name: item?.name || "",
    description: item?.description || "",
 
  };

  const [formData, setFormData] = useState(initialFormData);

 

  const handleSaveClick = async () => {
    try {
      if (!formData.name || formData?.name?.trim()==="") {
        toast.error("category is required and cannot be empty");
        return
      }
  
      if (!formData.description || formData?.description?.trim()==="") {
        toast.error("description  is required and cannot be empty");
        return
      }
      await userRequest.put(`/admin/static/updateCategory/${item?._id}`, formData);
      navigate("/staticcontents/faq");
      toast.success("Updated successfully!");
    } catch (error) {
      console.error("Error saving data:", error.message);
      toast.error("Error saving data");
    }
  };
  
  const handleDiscard = () => {
    navigate("/staticcontents/faq");
  };

  const breadcrumb = [
    { label: "Faq Category", path: "/staticcontents/faq" },
    { label: "Edit Faq Category", path: "/staticcontents/faq/editFaqCategory" },
  ];

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={breadcrumb}
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={handleDiscard}
        onSaveClick={handleSaveClick}
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <div className={styles.tableContainer}>
           
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Category</p>
                </div>
                <div className={styles.fieldInput}>
                <input
                    type="text"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Description</p>
                </div>
                <div className={styles.fieldInput}>
                
                     <textarea
                    type="text"
                    value={formData.description}
                    onChange={(e) =>
                      setFormData({ ...formData, description: e.target.value })
                    }
                  />
                  
                </div>
              </div>
            </div>
           
           
            <div className={styles.footer}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditFaqCategory;


