import React from "react";
import PrivateRoute from "./PrivateRoute";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/auth/login/Login";

import PanditProfileManagement from "../pages/PanditProfileManagement/PanditProfileManagement";
import PanditKYCManagement from "../pages/PanditKYCManagement/PanditKYCManagement";
import Dashboard from "../pages/Dashboard/Dashboard";
import ViewKYCPrice from "../pages/PanditKYCManagement/KYCPrice/ViewKYCPrice/ViewKYCPrice";
import SetKYCPrice from "../pages/PanditKYCManagement/KYCPrice/SetKYCPrice/SetKYCPrice";
import ViewKYCDetails from "../pages/PanditKYCManagement/ViewKYCDetails/View/ViewKYCDetails";
import SvarnaManagement from "../pages/SwarnaManagement/SvarnaManagement";
import CreateCampaign from "../pages/SwarnaManagement/Campaign/CreateCampaign/CreateCampaign";
import PayoutManagement from "../pages/PayoutManagement/PayoutManagement";
import PayoutDetails from "../pages/PayoutManagement/PayoutDetails/PayoutDetails";
import ForgotPassword from "../pages/auth/forgotPassword/ForgotPassword";
import ResetPassword from "../pages/auth/resetPassword/ResetPassword";
import ViewKYCPriceDetail from "../pages/PanditKYCManagement/KYCPrice/ViewKYCPrice/ViewKYCPriceDetail/ViewKYCPriceDetail";
import ViewCampaign from "../pages/SwarnaManagement/Campaign/ViewCampaign/ViewCampaign";
import EditCampaign from "../pages/SwarnaManagement/Campaign/EditCampaign/EditCampaign";
import SamhitaManagement from "../pages/SamhitaManagement/SamhitaManagement";
import CreateSamhita from "../pages/SamhitaManagement/CreateSamhita/CreateSamhita";
import ViewSamhita from "../pages/SamhitaManagement/ViewSamhita/ViewSamhita";
import NotFoundPage from "./404Page/PageNotFound";
import BannerManagement from "../pages/BannerManagement/BannerManagement";
import CreateBanner from "../pages/BannerManagement/CreateBanner/CreateBanner";
import ViewBanner from "../pages/BannerManagement/ViewBanner/ViewBanner";
import UserManagement from "../pages/UserManagement/UserManagement";
import UserDetails from "../pages/UserManagement/Details/UserDetails";
import NotificationManagement from "../pages/NotificationManagement/NotificationManagement";
import CreateNotification from "../pages/NotificationManagement/CreateNotification/CreateNotification";
import EditBanner from "../pages/BannerManagement/EditBanner/EditBanner";
import DevalayaManagement from "../pages/Master/devalayaManagement/DevalayaManagement";

import CreateTejPoints from "../pages/SwarnaManagement/TejPoints/CreateTejPoints/CreateTejPoints";
import HowTaskCenterWorks from "../pages/HowTaskCenterWorks/HowTaskCenterWorks";
import RolesAndPermissionsManagement from "../pages/RolesAndPermissionsManagement/RolesAndPermissionsManagement";
import CreateRoles from "../pages/RolesAndPermissionsManagement/Roles/CreateRoles/CreateRoles";
import ViewTejPoints from "../pages/SwarnaManagement/TejPoints/ViewTejPoints/ViewTejPoints";
import EditTejPoints from "../pages/SwarnaManagement/TejPoints/EditTejPoints/EditTejPoints";
import ViewRoles from "../pages/RolesAndPermissionsManagement/Roles/ViewRoles/ViewRoles";
import EditRoles from "../pages/RolesAndPermissionsManagement/Roles/EditRoles/EditRoles";
import UserActvityManagement from "../pages/UserActvityManagement/UserActvityManagement";
import UtsavManagement from "../pages/Master/UtsavManagement/UtsavManagement";
import ChildKYCManagement from "../pages/ChildKYCManagement/ChildKYCManagement";
import ViewChildKYCDetails from "../pages/ChildKYCManagement/ViewChildKYC/ViewChildKYCDetails";
import SamhitaType from "../pages/Master/Samhita/SamhitaType";
import MasterPandit from "../pages/Master/Pandit/MasterPandit";
import TaskManagement from "../pages/Master/TaskMangement/TaskManagement";
import MasterDevotee from "../pages/Master/Devotee/MasterDevotee";
import MasterLanguage from "../pages/Master/Language/MasterLanguage";
import PoojaBookingManagement from "../pages/PoojaBookingManagement/PoojaBookingManagement";
import BookingDetails from "../pages/PoojaBookingManagement/Details/BookingDetails";
import MasterLocation from "../pages/Master/Location/MasterLocation";
import ViewMasterPooja from "../pages/Master/Pandit/MasterPooja/ViewMasterPooja/ViewMasterPooja";
import EditMasterPooja from "../pages/Master/Pandit/MasterPooja/EditMasterPooja/EditMasterPooja";
import CreateMasterPooja from "../pages/Master/Pandit/MasterPooja/CreateMasterPooja/CreateMasterPooja";
import ViewPanditProfileDetails from "../pages/PanditProfileManagement/ViewPanditProfileDetails/ViewPanditProfileDetails";

import TermsAndPolicies from "../pages/StaticsContents/TermsAndPolicies/TermsAndPolicies";
import EditTermsAndPolicies from "../pages/StaticsContents/TermsAndPolicies/EditTermsAndPolices/EditTermsAndPolicies";
import ViewTermsAndPolicies from "../pages/StaticsContents/TermsAndPolicies/ViewTermsAndPolices/ViewTermsAndPolicies";
import CreateTermsAndPolicies from "../pages/StaticsContents/TermsAndPolicies/CreateTermsAndPolices/CreateTermsAndPolicies";




import FaqManagement from "../pages/StaticsContents/FaqManagement/FaqManagement";
import CreateFaqCategory from "../pages/StaticsContents/FaqManagement/FaqCategory/createFaqCategory/CreateFaqCategory";
import EditFaqCategory from "../pages/StaticsContents/FaqManagement/FaqCategory/editFaqCategory/EditFaqCategory";
import ViewFaqCategory from "../pages/StaticsContents/FaqManagement/FaqCategory/viewFaqCategory/ViewFaqCategory";
import CreateFaq from "../pages/StaticsContents/FaqManagement/Faq/createFaq/CreateFaq";
import ViewFaq from "../pages/StaticsContents/FaqManagement/Faq/viewFaq/ViewFaq";
import EditFaq from "../pages/StaticsContents/FaqManagement/Faq/editFaq/EditFaq";





const RouterPage = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <PanditKYCManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/rolesandpermissions"
        element={
          <PrivateRoute>
            <RolesAndPermissionsManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/rolesandpermissions/createroles"
        element={
          <PrivateRoute>
            <CreateRoles />
          </PrivateRoute>
        }
      />
      <Route
        path="/rolesandpermissions/viewroles/:id"
        element={
          <PrivateRoute>
            <ViewRoles />
          </PrivateRoute>
        }
      />
      <Route
        path="/rolesandpermissions/editroles/:id"
        element={
          <PrivateRoute>
            <EditRoles />
          </PrivateRoute>
        }
      />
      <Route
        path="/panditprofilemanagement"
        element={
          <PrivateRoute>
            <PanditProfileManagement />
          </PrivateRoute>
        }
      />
        <Route
        path="/panditprofilemanagement/viewpanditprofiledetails/:id"
        element={
          <PrivateRoute>
            <ViewPanditProfileDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/panditkycmanagement"
        element={
          <PrivateRoute>
            <PanditKYCManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/childkycmanagement"
        element={
          <PrivateRoute>
            <ChildKYCManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/childkycmanagement/viewchildkycdetails"
        element={
          <PrivateRoute>
            <ViewChildKYCDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/panditkycmanagement/viewkycprice"
        element={
          <PrivateRoute>
            <ViewKYCPrice />
          </PrivateRoute>
        }
      />
      <Route
        path="/panditkycmanagement/viewkycpricedetails/:id"
        element={
          <PrivateRoute>
            <ViewKYCPriceDetail />
          </PrivateRoute>
        }
      />

      <Route
        path="/panditkycmanagement/setprice"
        element={
          <PrivateRoute>
            <SetKYCPrice />
          </PrivateRoute>
        }
      />
      <Route
        path="/panditkycmanagement/setprice/:id"
        element={
          <PrivateRoute>
            <SetKYCPrice />
          </PrivateRoute>
        }
      />
      <Route
        path="/panditkycmanagement/viewkycdetails/:id"
        element={
          <PrivateRoute>
            <ViewKYCDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/svarnamanagement"
        element={
          <PrivateRoute>
            <SvarnaManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/svarnamanagement/createcampaign"
        element={
          <PrivateRoute>
            <CreateCampaign />
          </PrivateRoute>
        }
      />
      <Route
        path="/svarnamanagement/viewcampaign/:id"
        element={
          <PrivateRoute>
            <ViewCampaign />
          </PrivateRoute>
        }
      />
      <Route
        path="/svarnamanagement/editcampaign/:id"
        element={
          <PrivateRoute>
            <EditCampaign />
          </PrivateRoute>
        }
      />
      <Route
        path="/svarnamanagement/createTejPoints"
        element={
          <PrivateRoute>
            <CreateTejPoints />
          </PrivateRoute>
        }
      />
      <Route
        path="/svarnamanagement/viewTejPoints"
        element={
          <PrivateRoute>
            <ViewTejPoints />
          </PrivateRoute>
        }
      />
      <Route
        path="/svarnamanagement/editTejPoints"
        element={
          <PrivateRoute>
            <EditTejPoints />
          </PrivateRoute>
        }
      />
      <Route
        path="/poojabookingmanagement"
        element={
          <PrivateRoute>
            <PoojaBookingManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/poojabookingmanagement/details/:id"
        element={
          <PrivateRoute>
            <BookingDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/howtaskcenterworks"
        element={
          <PrivateRoute>
            <HowTaskCenterWorks />
          </PrivateRoute>
        }
      />
      <Route
        path="/useractivitylog"
        element={
          <PrivateRoute>
            <UserActvityManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/payoutmanagement"
        element={
          <PrivateRoute>
            <PayoutManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/payoutmanagement/payoutdetails/:id"
        element={
          <PrivateRoute>
            <PayoutDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/samhitamanagement"
        element={
          <PrivateRoute>
            <SamhitaManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/samhitamanagement/createsamhita"
        element={
          <PrivateRoute>
            <CreateSamhita />
          </PrivateRoute>
        }
      />
      <Route
        path="/samhitamanagement/viewsamhita"
        element={
          <PrivateRoute>
            <ViewSamhita />
          </PrivateRoute>
        }
      />
      <Route
        path="/bannermanagement"
        element={
          <PrivateRoute>
            <BannerManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/bannermanagement/create"
        element={
          <PrivateRoute>
            <CreateBanner />
          </PrivateRoute>
        }
      />
      <Route
        path="/bannermanagement/view"
        element={
          <PrivateRoute>
            <ViewBanner />
          </PrivateRoute>
        }
      />
      <Route
        path="/bannermanagement/edit"
        element={
          <PrivateRoute>
            <EditBanner />
          </PrivateRoute>
        }
      />
      <Route
        path="/usermanagement"
        element={
          <PrivateRoute>
            <UserManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/usermanagement/details/:userId"
        element={
          <PrivateRoute>
            <UserDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/notificationmanagement"
        element={
          <PrivateRoute>
            <NotificationManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/notificationmanagement/createnotification"
        element={
          <PrivateRoute>
            <CreateNotification />
          </PrivateRoute>
        }
      />
    
             <Route
        path="/staticcontents/termsandconditions"
        element={
          <PrivateRoute>
            <TermsAndPolicies staticsType={"termsAndConditions"}/>
          </PrivateRoute>
        }
      />
      <Route
        path="/staticcontents/termsandconditions/create"
        element={
          <PrivateRoute>
            <CreateTermsAndPolicies/>
          </PrivateRoute>
        }
      />
      
      <Route
        path="/staticcontents/termsandconditions/edit/:id"
        element={
          <PrivateRoute>
            <EditTermsAndPolicies/>
          </PrivateRoute>
        }
      />
      
      <Route
        path="/staticcontents/termsandconditions/view/:id"
        element={
          <PrivateRoute>
            <ViewTermsAndPolicies/>
          </PrivateRoute>
        }
      />
     
        <Route
      path="/staticcontents/privacypolicy"
      element={
        <PrivateRoute>
          <TermsAndPolicies staticsType={"privacyPolicies"}/>
        </PrivateRoute>
      }
    />
          <Route
        path="/staticcontents/privacypolicy/create"
        element={
          <PrivateRoute>
            <CreateTermsAndPolicies/>
          </PrivateRoute>
        }
      />
      
      <Route
        path="/staticcontents/privacypolicy/edit/:id"
        element={
          <PrivateRoute>
            <EditTermsAndPolicies/>
          </PrivateRoute>
        }
      />
      
      <Route
        path="/staticcontents/privacypolicy/view/:id"
        element={
          <PrivateRoute>
            <ViewTermsAndPolicies/>
          </PrivateRoute>
        }
      />

<Route
        path="/staticcontents/faq"
        element={
          <PrivateRoute>
            <FaqManagement/>
          </PrivateRoute>
        }
      />
                 <Route
        path="/staticcontents/faq/createFaqCategory"
        element={
          <PrivateRoute>
            <CreateFaqCategory/>
          </PrivateRoute>
        }
      />
       <Route
        path="/staticcontents/faq/editFaqCategory/:id"
        element={
          <PrivateRoute>
            <EditFaqCategory/>
          </PrivateRoute>
        }
      />
       <Route
        path="/staticcontents/faq/viewFaqCategory/:id"
        element={
          <PrivateRoute>
            <ViewFaqCategory/>
          </PrivateRoute>
        }
      />

<Route
        path="/staticcontents/faq/createFaq"
        element={
          <PrivateRoute>
            <CreateFaq/>
          </PrivateRoute>
        }
      />
       <Route
        path="/staticcontents/faq/editFaq/:id"
        element={
          <PrivateRoute>
            <EditFaq/>
          </PrivateRoute>
        }
      />
       <Route
        path="/staticcontents/faq/viewFaq/:id"
        element={
          <PrivateRoute>
            <ViewFaq/>
          </PrivateRoute>
        }
      />
      
      <Route
        path="/master/taskmanagement"
        element={
          <PrivateRoute>
            <TaskManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/master/devalayamanagement"
        element={
          <PrivateRoute>
            <DevalayaManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/master/utsavmanagement"
        element={
          <PrivateRoute>
            <UtsavManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/master/samhitatype"
        element={
          <PrivateRoute>
            <SamhitaType />
          </PrivateRoute>
        }
      />
      <Route
        path="/master/pandit"
        element={
          <PrivateRoute>
            <MasterPandit />
          </PrivateRoute>
        }
      />
      <Route
        path="/master/pandit/createmasterpooja"
        element={
          <PrivateRoute>
            <CreateMasterPooja />
          </PrivateRoute>
        }
      />
      <Route
        path="/master/pandit/editmasterpooja"
        element={
          <PrivateRoute>
            <EditMasterPooja />
          </PrivateRoute>
        }
      />


<Route
        path="/master/pandit/viewmasterpooja"
        element={
          <PrivateRoute>
            <ViewMasterPooja />
          </PrivateRoute>
        }
      />
      
      <Route
        path="/master/devotee"
        element={
          <PrivateRoute>
            <MasterDevotee />
          </PrivateRoute>
        }
      />
      <Route
        path="/master/language"
        element={
          <PrivateRoute>
            <MasterLanguage />
          </PrivateRoute>
        }
      />
      <Route
        path="/master/location"
        element={
          <PrivateRoute>
            <MasterLocation />
          </PrivateRoute>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/resetPassword/:token" element={<ResetPassword />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default RouterPage;
