import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Spin } from "antd";
import { toast } from "sonner";
import {  userRequest } from "../../../../requestMethod";
import PageHeaderTwo from "../../../../components/PageHeader/PageHeaderTwo";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import styles from "../CreateTermsAndPolices/CreateTermsAndPolices.module.css";
import SelectComponent from "../../../../components/SelectComponent";

function EditTermsAndPolicies() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location.state || {};
  const [data, setData] = useState(item);
  const [languages , setLanguages] = useState([])

  const fetchLanguages = async()=>{
    try{
      let response = await userRequest.get('/master/getMasterLanguage')
      setLanguages(response?.data?.data)
    }
    catch(error){
      console.error("Error saving data:", error.message);
      toast.error("Error saving data");
    }
  }
  useEffect(()=>{
    fetchLanguages()
  },[])

  const handleChangeContent = (value) => {
    setData({ ...data, content: value });
  };
  const handleChange = (e) => {
    setData({ ...data, section: e.target.value });
  };


  const breadcrumb = [
    { label: item?.type==="termsAndConditions" ? "Terms & Conditions" : "Privacy Policy", path: item?.type==="termsAndConditions"? "/staticcontents/termsandconditions" : "/staticcontents/privacypolicy" },

    { label: "Edit", path: item?.type==="termsAndConditions" ? "/staticcontents/termsandconditions/edit" :"/staticcontents/privacypolicy/edit" },
  ];
    // Function to strip HTML tags and validate content
    const stripHtml = (html) => {
      const div = document.createElement("div");
      div.innerHTML = html;
      return div.textContent || div.innerText || "";
    };

  const handleSaveClick = async () => {
    try {
       // Validation
    if (!data?.section || data?.section?.trim() === "") {
      toast.error("Section is required and cannot be empty.");
      return;
    }

    const strippedContent = stripHtml(data?.content);

    if (!strippedContent || strippedContent.trim() === "") {
      toast.error("Content is required and cannot be empty.");
      return;
    }
 
    if (!data?.language ) {
      toast.error("Language is required");
      return;
    }
      console.log("data" , data)
      const response = await userRequest.put(
        `/admin/static/updateStaticContent/${data?._id}`,
        data
      );
      console.log("response", response);
      item?.type==="termsAndConditions"  ? 
      navigate("/staticcontents/termsandconditions") :
      navigate("/staticcontents/privacypolicy")

      toast.success("Updated successfully!");
    } catch (error) {
      console.error("Error saving data:", error.message);
      toast.error("Error saving data");
    }
  };

  const handleDiscard = () => {
    item?.type==="termsAndConditions" ? navigate("/staticcontents/termsandconditions") : navigate("/staticcontents/privacypolicy")

  };
 const languageOptions = languages?.map((item) => ({
    value: item?.language,
    label: item?.language,
  }));
  return (
    <Spin spinning={isLoading} size="small">
      <div className={styles.main}>
        <PageHeaderTwo
          breadcrumb={breadcrumb}
          discardLabel="Back"
          saveLabel="Save"
          onDiscardClick={handleDiscard}
          onSaveClick={handleSaveClick}
        />

        <div className={styles.contentContainerTask}>
      
          <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Section</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="section"
                    value={data?.section}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Language</p>
                </div>
                <div className={styles.fieldInput}>
                  <SelectComponent
                    options={languageOptions}
                    onSelect={(selectedOption) =>
                      setData({
                        ...data,
                        language: selectedOption.value,
                      })
                    }
                    initialSelectedOption={data.language}
                  />
                </div>
              </div>
            </div>

          {data?.content !== null && (
            <>
              <label>Content</label>
              <RichTextEditor
                value={data?.content}
                onChange={handleChangeContent}
              />
            </>
          )}
        </div>
      </div>
    </Spin>
  );
}

export default EditTermsAndPolicies;
