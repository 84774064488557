import React, { useEffect, useState } from "react";
import styles from "./createMasterPooja.module.css";
import { userRequest } from "../../../../../requestMethod";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import FileUpload from "../../../../../components/FileUpload/FileUpload";

function CreateMasterPooja() {
  const navigate = useNavigate();
  const [poojaCategories, setPoojaCategories] = useState([]);
  const [poojaMaterialOptions, setPoojaMaterialOptions] = useState([]);
  const [daivaData, setDaivaData] = useState([]);

  const unitOptions = [
    { label: "Kg", value: "kg" },
    { label: "Gm", value: "gm" },
    { label: "Ltr", value: "ltr" },
    { label: "Pcs", value: "pcs" },
  ];
  const initialFormData = {
    poojaCategory: "",
    poojaName: "",
    poojaImage:"",
    poojaDescription:"",
    poojaDiety:""
  };

  const [poojaRituals, setPoojaRituals] = useState([
    { name: "", description: "", },
  ]);
  const [poojaItems, setPoojaItems] = useState([
    { name: "", quantity: "", unit: "" },
  ]);
  const [prasadamItems, setPrasadamItems] = useState([
    { name: "", quantity: "", unit: "" },
  ]);
  const [formData, setFormData] = useState(initialFormData);

  // const categoryOptions = poojaCategories.map()

  useEffect(() => {
    fetchPoojaCategory();
  }, []);

  const fetchPoojaCategory = async () => {
    try {
      const response = await userRequest.get(`/master/getAllPoojaCategories`);
      const daiva = await userRequest.get(`/master/getDaiva`);
      setPoojaCategories(response.data.data);
      setDaivaData(daiva.data.daiva);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchPoojaMaterial();
  }, []);
  const fetchPoojaMaterial = async () => {
    try {
      const response = await userRequest.get(`/master/getMasterPoojaMaterial`);
      let data = response.data.data;
      const options = data?.map((item) => ({
        label: item?.name,
        value: item?.name,
      }));
      setPoojaMaterialOptions(options);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const saveData = async () => {
    try {
      const updatedFormData = {
        ...formData,
        poojaRituals,
        poojaItems,
        prasadamItems,
      };
      // alert(JSON.stringify(updatedFormData))
      // return
      await userRequest.post("/master/createPoojaByAdmin", updatedFormData);
      setFormData(initialFormData);
      setPoojaRituals({ name: "", quantity: "", unit: "" });
      setPoojaItems({ name: "", quantity: "", unit: "" });
      setPrasadamItems({ name: "", quantity: "", unit: "" });
      navigate("/master/pandit");
      toast.success("Saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error.message);
      toast.error("Failed to save data. Please try again later.");
    }
  };

  // ---------------------------------------------------Rituals-----------------------------------------------

  const handleAddRitual = () => {
    setPoojaRituals([...poojaRituals, { name: "", quantity: "", unit: "" }]);
  };

  const handleDeleteRitual = (index) => {
    const updatedRituals = poojaRituals.filter((_, i) => i !== index);
    setPoojaRituals(updatedRituals);
  };

  const handleChangeRitual = (index, event) => {
    const { name, value } = event.target;
    const updatedRituals = poojaRituals.map((ritual, i) =>
      i === index ? { ...ritual, [name]: value } : ritual
    );
    setPoojaRituals(updatedRituals);
  };

  // -------------------------------------------------------Pooja Items-------------------------------------------

  const handleAddPoojaItem = () => {
    setPoojaItems([...poojaItems, { name: "", quantity: "", unit: "" }]);
  };

  const handleDeletePoojaItem = (index) => {
    const updatedPoojaItem = poojaItems.filter((_, i) => i !== index);
    setPoojaItems(updatedPoojaItem);
  };

  const handleChangePoojaItem = (index, event) => {
    const { name, value } = event.target;
    const updatedPoojaItem = poojaItems.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setPoojaItems(updatedPoojaItem);
  };

  const handleChangeUnitPoojaItem = (index, event) => {
    const { value } = event.target;
    const updatedItems = [...poojaItems];
    updatedItems[index] = { ...updatedItems[index], unit: value };
    setPoojaItems(updatedItems);
  };

  // -------------------------------------------------Prasadam Items--------------------------------------------

  const handleAddPrasadamItems = () => {
    setPrasadamItems([...prasadamItems, { name: "", quantity: "", unit: "" }]);
  };

  const handleDeletePrasadamItems = (index) => {
    const updatedPrasadamItems = prasadamItems.filter((_, i) => i !== index);
    setPrasadamItems(updatedPrasadamItems);
  };

  const handleChangePrasadamItems = (index, event) => {
    const { name, value } = event.target;
    const updatedPrasadamItems = prasadamItems.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setPrasadamItems(updatedPrasadamItems);
  };

  const handleChangeUnitPrasadamItems = (index, event) => {
    const { value } = event.target;
    const updatedItems = [...prasadamItems];
    updatedItems[index] = { ...updatedItems[index], unit: value };
    setPrasadamItems(updatedItems);
  };
  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p>
            <span onClick={() => navigate("/master/pandit")}>Master Pooja</span>{" "}
            <span className={styles.activePageStyle}>
              &gt; Create Pooja Type
            </span>
          </p>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Select Category</p>
            </div>
            <div className={styles.fieldInput}>
              <select
                value={formData.poojaCategory || ""}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    poojaCategory: e.target.value,
                  })
                }
              >
                <option value="">Select</option>
                {poojaCategories?.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.category}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Pooja Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.poojaName}
                onChange={(e) =>
                  setFormData({ ...formData, poojaName: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Select Deity</p>
            </div>
            <div className={styles.fieldInput}>
              <select
                value={formData.poojaDiety || ""}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    poojaDiety: e.target.value,
                  })
                }
              >
                <option value="">Select</option>
                {daivaData?.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.daivaName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Pooja Image</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                label="Upload Pooja Image"
                onChange={(fieldName, file) =>
                  setFormData({ ...formData, poojaImage: file })
                }
                fieldName="profileImage"
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Pooja Description</p>
            </div>
            <div className={styles.fieldInput}>
              <textarea
                value={formData.poojaDescription}
                onChange={(e) =>
                  setFormData({ ...formData, poojaDescription: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
        </div>
        <div className={styles.rowContainer}>
          <div>
            <label className={styles.poojaTypeLabel}>Add Ritual</label>
          </div>
          {poojaRituals?.map((ritual, index) => (
            <
            >
              {/* <div className={styles.poojaTypeInputGroup}>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={ritual.name}
                    onChange={(event) => handleChangeRitual(index, event)}
                    className={styles.poojaTypeInput}
                    placeholder="Enter"
                  />
                </div>
              </div> */}
                <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Name</p>
            </div>
            <div className={styles.fieldInput}>
            <input
                    type="text"
                    name="name"
                    value={ritual.name}
                    onChange={(event) => handleChangeRitual(index, event)}
                    className={styles.poojaTypeInput}
                    placeholder="Enter"
                  />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Description</p>
            </div>
            <div className={styles.fieldInput}>
            <textarea
                    type="text"
                    name="description"
                    value={ritual.description}
                    onChange={(event) => handleChangeRitual(index, event)}
                    // className={styles.poojaTypeInput}
                    placeholder="Enter"
                  ></textarea>
            </div>
          </div>
        </div>
              {poojaRituals.length > 1 && (
                <button
                  onClick={() => handleDeleteRitual(index)}
                  className={styles.poojaTypeDeleteBtn}
                ></button>
              )}
            </>
        
          ))}
          <button onClick={handleAddRitual}>Add More</button>
        </div>

        <div className={styles.rowContainer}>
          <label className={styles.poojaTypeLabel}>Add Pooja Item</label>
          {poojaItems?.map((item, index) => (
            <div key={index} className={styles.poojaTypeContainer}>
              <div className={styles.poojaTypeInputGroup}>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Name</label>
                  <select
                    name="name"
                    value={item.name || ""}
                    onChange={(event) => handleChangePoojaItem(index, event)}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {poojaMaterialOptions.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Quantity</label>
                  <input
                    type="text"
                    name="quantity"
                    value={item.quantity}
                    onChange={(event) => handleChangePoojaItem(index, event)}
                    className={styles.poojaTypeInput}
                    placeholder="Enter"
                  />
                </div>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Unit</label>
                  <select
                    value={item.unit || ""}
                    onChange={(event) =>
                      handleChangeUnitPoojaItem(index, event)
                    }
                  >
                    <option value="">Select</option>
                    {unitOptions.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {poojaItems.length > 1 && (
                <button
                  onClick={() => handleDeletePoojaItem(index)}
                  className={styles.poojaTypeDeleteBtn}
                ></button>
              )}
            </div>
          ))}
          <button onClick={handleAddPoojaItem}>Add More</button>
        </div>

        <div className={styles.rowContainer}>
          <label className={styles.poojaTypeLabel}>Add Prasadam Items</label>
          {prasadamItems.map((item, index) => (
            <div key={index} className={styles.poojaTypeContainer}>
              <div className={styles.poojaTypeInputGroup}>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={item.name}
                    onChange={(event) =>
                      handleChangePrasadamItems(index, event)
                    }
                    className={styles.poojaTypeInput}
                    placeholder="Enter"
                  />
                </div>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Quantity</label>
                  <input
                    type="text"
                    name="quantity"
                    value={item.quantity}
                    onChange={(event) =>
                      handleChangePrasadamItems(index, event)
                    }
                    className={styles.poojaTypeInput}
                    placeholder="Enter"
                  />
                </div>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Unit</label>
                  <select
                    value={item.unit || ""}
                    onChange={(event) =>
                      handleChangeUnitPrasadamItems(index, event)
                    }
                  >
                    <option value="">Select</option>
                    {unitOptions.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {prasadamItems.length > 1 && (
                <button
                  onClick={() => handleDeletePrasadamItems(index)}
                  className={styles.poojaTypeDeleteBtn}
                ></button>
              )}
            </div>
          ))}
          <button onClick={handleAddPrasadamItems}>Add More</button>
        </div>

        <div className={styles.footer}>
          <button onClick={() => navigate("/master/pandit")}>Discard</button>
          <button onClick={saveData}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default CreateMasterPooja;
