
import React, { useEffect, useState } from "react";
import styles from "./samhitaManagement.module.css";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import { Popconfirm, Spin, Switch } from "antd";
import SamhitaFilter from "./SamhitaFilter";
import useDebounce from "../../utils/useDebounce";
import { handleExportDoc } from "../../utils/exportDoc";
import PageHeader from "../../components/PageHeader/PageHeader";
import SearchFilterComponent from "../../components/SearchFilter/SearchFilterComponent";
import TableComponent from "../../components/TableComponent/TableComponent";

function SamhitaManagement() {
  const [samhitaData, setSamhitaData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const navigate = useNavigate();
  const [selectedSamhita, setSelectedSamhita] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [categoriesData, setCategoriesData] = useState([]);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [languageData, setLanguageData] = useState([]);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, filterData]);

  const fetchData = async () => {
    try {
      const filterQuery = filterData
        ? `&category=${filterData.category}&subCategory=${filterData.subCategory}&languages=${filterData.languages}`
        : "";
      const response = await userRequest.get(
        `/admin/samhita/getAdminSamhita?search=${debouncedSearchQuery}${filterQuery}&page=${page}`
      );
      setSamhitaData(response.data.data);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewClick = (samhita) => {
    setSelectedSamhita(samhita);
    navigate(`/samhitamanagement/viewsamhita`, { state: { samhita } });
  };

  const handleChange = async (id, activeStatus) => {
    try {
      const newActiveStatus = !activeStatus;
      await userRequest.patch(`/admin/samhita/updateSamhitaStatus`, {
        samhitaId: id,
        activeStatus: newActiveStatus,
      });
      fetchData();
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  const fetchFilterData = async () => {
    try {
      const categories = await userRequest.get(
        "/master/getMasterSamhitaCategory"
      );
      const subCategories = await userRequest.get(
        "/master/getMasterSamhitaSubCategory"
      );
      const languages = await userRequest.get("/master/getMasterLanguage");
      setCategoriesData(categories.data.data);
      setSubCategoriesData(subCategories.data.data);
      setLanguageData(languages.data.data);
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
  };

  const handleFilterClick = () => {
    fetchFilterData();
    setIsModalOpen(true);
  };

  const handleModalOk = (data) => {
    setFilterData(data);
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handleExport = () => {
    handleExportDoc(
      "/admin/samhita/exportSamhitaDataToExcel",
      "SamhitaManagement.xlsx"
    );
  };

  const columns = [
    {
      title: "S.No.",
      key: "serialNumber",
      render: (item, index) => (page - 1) * 10 + index + 1,
    },
    { title: "Samhita Name", key: "samhitaName" },
    { title: "Type", key: "samhitaType" },
    {
      title: "Category",
      key: "category",
      render: (item) => item.category.category,
    },
    {
      title: "Subcategory",
      key: "subCategory",
      render: (item) => item.subCategory.map(sub => sub.subCategory).join(", ")
      
    },
    {
      title: "Language",
      key: "languages",
      render: (item) => item.languages.language,
    },
    { title: "Author", key: "author" },
    {
      title: "PDF",
      key: "pdf",
      render: (item) => (
        <span
          className={styles.viewDetails}
          onClick={() => handleViewClick(item)}
        >
          View
        </span>
      ),
    },
    {
      title: "Action",
      key: "activeStatus",
      render: (item) => (
        <Popconfirm
          title={`Are you sure to ${
            item.activeStatus ? "deactivate" : "activate"
          } ${item.samhitaName}?`}
          onConfirm={() => handleChange(item._id, item.activeStatus)}
          okText="Yes"
          cancelText="No"
        >
          <Switch size="small" checked={item.activeStatus} />
        </Popconfirm>
      ),
    },
  ];

  const customWidths = {
    serialNumber: "8vw",
    samhitaName: "20vw",
    samhitaType: "20vw",
    category: "10vw",
    subCategory: "20vw",
    languages: "20vw",
    author: "8vw",
    pdf: "20vw",
    activeStatus: "20vw",
  };

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Samhita Management", path: "/samhitamanagement" },
        ]}
        buttonLabel="Add New"
        onButtonClick={() => navigate("/samhitamanagement/createsamhita")}
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <SearchFilterComponent
            setSearchQuery={setSearchQuery}
            onExportClick={handleExport}
            onFilterClick={handleFilterClick}
            filter
          />
          <TableComponent
            columns={columns}
            data={samhitaData}
            loading={loading}
            onDelete={() => {}}
            onStatusChange={handleChange}
            currentPage={currentPage}
            totalPages={totalPages}
            onNext={handleNext}
            onPrevious={handlePrevious}
            customWidths={customWidths}
          />
        </div>
      </div>
      <SamhitaFilter
        isModalOpen={isModalOpen}
        handleOk={handleModalOk}
        handleCancel={handleModalCancel}
        categoriesData={categoriesData}
        subCategoriesData={subCategoriesData}
        languageData={languageData}
      />
    </div>
  );
}

export default SamhitaManagement;
