import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import styles from "./addPandit.module.css";
import { toast } from "sonner";
import SelectComponent from "../../../components/SelectComponent";
import FileUpload from "../../../components/FileUpload/FileUpload";
import { userRequest } from "../../../requestMethod";

const AddPandit = ({ isModalOpen, handleCancel,refreshData,setRefreshData }) => {
  const initialFormData = {
    firstName: "",
    lastName: "",
    gender: "",
    mobile: "",
    email: "",
    profilePic: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [resetKey, setResetKey] = useState(0); // Key to force remount

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOk = async () => {
    if (Object.values(formData).some(field => !field)) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      await userRequest.post(`/userManagement/createPandit`, formData);
      toast.success("Pandit created successfully");
      resetForm();
      handleCancel();
      setRefreshData(!refreshData)
    } catch (error) {
      console.log("Error creating Pandit:", error);
      toast.error("Failed to create Pandit");
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setResetKey((prevKey) => prevKey + 1); // Change the reset key
  };

  const option = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const handleGenderSelect = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      gender: selectedOption.value,
    }));
  };

  return (
    <Modal
      visible={isModalOpen}
      onCancel={() => {
        resetForm();
        handleCancel();
        setRefreshData(!refreshData)
      }}
      onOk={handleOk}
      width={"29vw"}
      className={styles.modal}
    >
      <div className={styles.modalTitle}>
        <p>Add Pandit</p>
      </div>
      <div className={styles.contentMain}>
        {/* First Name Field */}
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>First Name</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              key={`firstName-${resetKey}`} // Reset key for the input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Last Name Field */}
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Last Name</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              key={`lastName-${resetKey}`} // Reset key for the input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Gender Field */}
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Gender</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              key={`gender-${resetKey}`} // Reset key for the select
              options={option}
              onSelect={handleGenderSelect}
              // initialSelectedOption={option.find(opt => opt.value === formData.gender)}
            />
          </div>
        </div>

        {/* Mobile Field */}
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Mobile</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              key={`mobile-${resetKey}`} // Reset key for the input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Email Field */}
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Email</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              key={`email-${resetKey}`} // Reset key for the input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Profile Pic Field */}
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Profile Pic</p>
          </div>
          <div className={styles.fieldInput}>
            <FileUpload
              key={`profilePic-${resetKey}`} // Reset key for the file upload
              label="Upload Media"
              onChange={(fieldName, file) =>
                setFormData({ ...formData, profilePic: file })
              }
              fieldName="profilePic"
            />
          </div>
        </div>

        <div className={styles.modalButton}>
          <button onClick={() => {
            resetForm();
            handleCancel();
          }}>Cancel</button>
          <button onClick={handleOk}>Add</button>
        </div>
      </div>
    </Modal>
  );
};

export default AddPandit;
